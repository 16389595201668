import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.css']
})
export class UnderConstructionComponent {

  constructor(private _location: Location) {}

  backClicked() {
    this._location.back();
  }

}
